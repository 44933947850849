import React from 'react';
import { createRoot } from 'react-dom/client'; // Mettez à jour l'importation
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from "@react-oauth/google"
import { register } from './utils/serviceWorker';
import { LoadingProvider } from './context/LoadingProvider';
import { NotificationProvider } from './context/NotificationProvider';
import NotificationService from './services/NotificationService';
// Utilisez createRoot pour rendre votre application
const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
      <GoogleOAuthProvider clientId='10512603853-hkctnf7psru04br31fnhoqo1q27enq65.apps.googleusercontent.com'>
        <Routes>
          <Route path="/*" element={<LoadingProvider><NotificationProvider><App /></NotificationProvider></LoadingProvider>} />
        </Routes>
        </GoogleOAuthProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
register();
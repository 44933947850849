export const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/api';

export const endpoints = {
  csrf:'/sanctum/csrf-cookie',
  loginUrl: '/auth/login',
  register: '/auth/register',
  refreshToken: '/auth/me',
  getProfil: '/auth/me',
  getAbonnement: '/admin/service/souscription',
  invite: '/admin/invite',
  googleConnect: 'auth/login/google/callback',
  appleConnect: '/apple/auth',
  verify: '/client/verify/otp',
  changeStatus: '/admin/service/commande/change/etat',
  newSouscription: '/admin/service/souscription/add',
  getUser: 'admin/users?page=1&limit=10&type=All',
  getAdminUser: '/admin/users',
  disableSubscription: '/admin/service/souscription/desactive',
  stripeVerify: '/admin/verify-payment',
  getService: '/admin/service',
  newUser: '/admin/new/user',
  bloqueUser: '/admin/user/disable',
  updateUser: '/admin/edit/user',
  activeUser: '/admin/user/enable',
  getDevise: '/admin/service/devise',
  disableService: '/admin/service/disable',
  newService: '/admin/service/new',
  updateService: '/admin/service/edit',
  newCat: '/admin/post/new/categorie',
  updateCat: '/admin/post/edit/categorie',
  getPost: '/admin/post',
  getPinnedPost: '/admin/post/epingler',
  getTags: '/admin/post/tags',
  getCat: '/admin/post/categories',
  getComment: 'admin/post/comment',
  newComment: '/admin/post/comment',
  commentComment: '/admin/post/comment/comment',
  newPost: '/admin/post/new',
  updatePost: '/admin/post/edit',
  getStats: '/admin/stats',
  newTag: '/admin/post/new/tags',
  editTag: '/admin/post/edit/tags',
  blockComment: '/admin/comment/disable',
  lang: '/admin/post/lang',
  messages: '/admin/messages',
  newLang: '/admin/new/lang',
  editLang: '/admin/edit/lang',
  newMessage: '/admin/new/message',
};